<template>
  <div>
    <div class="side-bar">
      <h1>导航目录</h1>
      <ul>
        <li v-for="(nav, index) in sildeNavs" :key="index">
          <a href="#" @click="scrollToAnywhere(nav.id, 5)">{{ nav.nav }}</a>
        </li>
      </ul>
      <button class="mt-30" @click="tryYiDao">立即体验</button>
    </div>
    <div class="mt-down-header flex-center">
      <div class="content">
        <base-breadcrumb :crumbs="items" />
        <div id="step1" class="c-border download">
          <img
            src="https://static.ainvestcn.com/big_img/product-intro%25402x.png"
            alt
            width="100%"
            height="100%"
          >
        </div>
        <div class="bg-split book">
          <h3 class="mb-30">自动炒股程序操作教程</h3>
          <p>
            完成以下几步即可使用我们的自动炒股程序，如有疑请联系我们，谢谢！
          </p>
          <div class="step-list">
            <ul>
              <div v-for="(step, index) in data" :id="'step'+(step.id+1)" :key="index" class="pt-scroll">
                <li><h4>{{ step.step }}</h4></li>
                <img :src="step.imgUrl" width="100%" alt>
                <!-- <p>{{ step.note }}</p>
                <img v-if="step.imgUrl2.length !== 0" :src="step.imgUrl2" alt> -->
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      items: [
        {
          text: '主页',
          route: '/#home'
        },
        {
          text: '自动交易',
          route: ''
        }
      ],
      sildeNavs: [
        {
          id: 1,
          nav: '产品介绍'
        },
        {
          id: 2,
          nav: '注册/登录'
        },
        {
          id: 3,
          nav: '选择策略'
        },
        {
          id: 4,
          nav: '开启交易'
        },
        {
          id: 5,
          nav: '个人信息'
        }
      ],
      data: [
        {
          id: 1,
          step: '注册/登录',
          imgUrl: 'https://static.ainvestcn.com/Tutorial/doc-1.jpg',
          imgUrl2: '',
          note: ''
        },
        {
          id: 2,
          step: '选择策略',
          imgUrl: 'https://static.ainvestcn.com/Tutorial/doc-2.jpg',
          imgUrl2: '',
          note: ''
        },
        {
          id: 3,
          step: '开启交易',
          imgUrl: 'https://static.ainvestcn.com/Tutorial/doc-3.jpg',
          imgUrl2: '',
          note: ''
        },
        {
          id: 4,
          step: '个人信息',
          imgUrl: 'https://static.ainvestcn.com/Tutorial/doc-4.jpg',
          imgUrl2: '',
          note: ''
        }
      ]
    }
  },

  computed: {
    ...mapState({
      loginStatus: state => state.login.loginStatus
    })
  },

  mounted() {
    window.scrollTo(0, 1)
  },

  methods: {
    scrollToAnywhere: function(anchorId, speed) {
      const dom = document.body.scrollTop ? document.body : document.documentElement
      let currentScrollTop = dom.scrollTop
      const anchor = document.getElementById('step' + anchorId)
      console.log('anchor', anchor)
      const gogogo = function() {
        currentScrollTop += (anchor.offsetTop - currentScrollTop) / speed
        if (Math.abs(anchor.offsetTop - currentScrollTop) < 1) {
          return
        }
        console.log('in gogoo', currentScrollTop)
        dom.scrollTop = currentScrollTop
        requestAnimationFrame(gogogo)
      }
      gogogo()
    },

    /**
     * 立即体验
     * 判断是否登录
     */
    tryYiDao: function() {
      this.loginStatus ? this.$router.push('/leaderboard') : this.$router.push('/login')
    }

  }
}
</script>

<style lang="scss" scoped>
.pt-scroll{
  padding-top: 105px
}

.side-bar{
  position: fixed;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 50px 20px;

  h1 {
    color: #666;
    font-size: 26px;
  }
  ul {
    padding: 0;
    margin-top: 30px;
    li {
      list-style: none;
      margin-bottom: 10px;
      a {
        color: #666;
      }
      a:hover {
        color: #EB483F;
        text-decoration: none;
      }
    }
  }
}

.content {
  padding-bottom: 50px;
  max-width: 1000px;
  color: grey;

  h3 {
    text-align: center;
  }

  img.app {
    width: 80px;
    height: 80px;
    margin-right: 50px;
  }
}

.step-list {
  img {
    margin: 30px 0;

  }
}

button {
  // width: 100px;
  // height: 40px;
  border: 1px solid #ff3333ff;
  background-color: #fff;
  color: #ff3333ff;
  // border-radius: 10px;
  // font-size: 20px;
  font-family: PingFang-SC-Medium;
  font-weight: 500;
}

button:hover {
  border: 0;
  background: rgba(255, 51, 51, 1);
  color: #fff;
}

.download {
  padding: 50px;
  margin-bottom: 60px;
}

.book {
  padding: 50px;
}
</style>
